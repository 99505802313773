<template>
  <div class="project-list">
    <div class="plan-header">
      <div>
        <div class="title">项目案例</div>
        <div class="info">助力千行百业实现高效率、低成本、规模化的 AI 创新和落地</div>
      </div>
    </div>
    <div class="plan-content">
      <div class="title">案例总览</div>
      <div class="plan-list-box">
        <div class="plan-list">
          <div class="list-item"
               v-for="(item,index) in projectList"
               :class="listIndex==index?'active':''"
               @click="listIndex=index"
               :key="index"
          >
            {{item.caseTypeName}}
          </div>
        </div>
        <div class="plan-info" v-if="projectList[listIndex]">
          <div class="info-item"
               :style="{backgroundImage:'url('+item.casePicture+')'}"
               v-for="(item,index) in projectList[listIndex].caseList"
               :key="index"
               @click="goProjectDetail(item.id)"
          >
            <div>
              <div class="title">{{item.caseName}}</div>
              <div class="info">{{item.caseDesc}}</div>
              <div class="more">了解更多></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "projectList",
  data(){
    return {
      projectList:[],
      listIndex:0,
    }
  },
  created() {
    this.getProjectList();
  },
  methods:{
    getProjectList(){
      this.$get('/website/case/group/list ').then((res)=>{
        this.projectList=res.rows;
      }).catch((err)=>{
        this.msgError(err.msg)
      })
    },
    goProjectDetail(id){
      this.$router.push({
        name:'projectDetail',
        query:{
          id:id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.project-list{
  padding-top: 64px;
  .plan-header{
    width: 100%;
    height: 507px;
    background-image: url("../../assets/plan_header-img.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    .title{
      font-size: 38px;
    }
    .info{
      margin-top: 20px;
      font-size: 24px;
    }
  }
  .plan-content{
    margin-top: 80px;
    padding-bottom: 30px;
    .title{
      text-align: center;
      font-size: 38px;
    }
    .plan-list-box{
      width: 1200px;
      margin: 0 auto;
      margin-top: 80px;
      display: flex;
      justify-content: space-around;
      .plan-list{
        width: 120px;
        .list-item{
          border-left: 2px solid #797979;
          color: #797979;
          text-align: center;
          padding: 10px 0;
          cursor: pointer;
        }
        .list-item.active{
          border-left: 2px solid black;
          color: black;

        }
      }
      .plan-info{
        width: 900px;
        .info-item{
          width: 100%;
          height: 400px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          margin-top: 30px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          text-align: center;
          .info{
            font-size: 18px;
            margin-top: 10px;
          }
          .more{
            margin-top: 30px;
          }
        }
        .info-item:first-of-type{
          margin-top: 0;
        }
      }
    }
  }
}
</style>
